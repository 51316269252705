module.exports = {
    "baseUrl": "https://staging.trillium.health",
    "baseUrl2": "https://stagingnew.trillium.health",
    "dashboardUrl": "https://stagingnew.trillium.health",
    "systemName": "System dev",
    "chatServer": "https://staging.trillium.health:8082/chatWebsocket",
    "videoServer": "https://staging.trillium.health:9090/schedularWebsocket",
    "prodStatus": true,
    "newChatServer": "https://api.staging.trillium.health",
    "patientChatUrl": "https://m.staging.trillium.health"
}